import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: Notification[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    token: any='';
    user: User;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private httpClient: HttpClient,
        private _viewContainerRef: ViewContainerRef,
        private userservice:UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.userservice.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
          this.user = user;

          // Mark for check
          this._changeDetectorRef.markForCheck();
      });
        // Subscribe to notification changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {

                // Load the notifications
                this.notifications = notifications;

                // Calculate the unread count
                this._calculateUnreadCount();
                this.call90sec();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    call90sec(){
        // if(!this.stoploading){
        this.loginthignsboard()
        setTimeout(() => {
          this.call90sec()
        }, 30000);
    // }
      }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void
    {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void
    {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
    }

    loginthignsboard(){
        const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders()
          .set('osd-xsrf', 'true')
          var kibanaurl=''
          const redirect_uri= "https://iomldevtb.mydigitalpetro.com/api/auth/login"
         
        const URL = redirect_uri
        const creds = {username: "tenant@thingsboard.org", password: "digitalpetro"}
        this.httpClient.post(URL,creds,{ headers: httpOptions }).subscribe((data: any) => {
            console.log("Response tb", data)
            if(data && data.token){
              this.token = data.token
              this.getopenalerts()
            }
            else{
            //   this.toast.sToast('error', "Could not fetch data")
            }
          }, error =>{
            console.log("error", error)
          })
      }
      

    getopenalerts(){   
        // this.loadingapi=true 
        // const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders({
          'X-Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        });
      
        // const redirect_uri= `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?searchStatus=ACTIVE&pageSize=100&page=0&sortOrder=DESC`
        var redirect_uri= `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?status=ACTIVE_UNACK&pageSize=100&page=0&sortProperty=createdTime&sortOrder=DESC`
        if(this.user.email == 'tenant1@rakaz.com'){
          redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?status=ACTIVE_UNACK&pageSize=100&page=0&textSearch=commercial1&sortOrder=DESC`
        }else if(this.user.email == 'tenant2@rakaz.com'){
          redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?pageSize=100&status=ACTIVE_UNACK&page=0&textSearch=commercial2&sortOrder=DESC`
        }     
        const URL = redirect_uri
        
        this.httpClient.get(URL,{ headers: httpOptions }).subscribe((data: any) => {
          data.data.forEach(ele =>{
            if(ele.details && (ele.details.temp_1 || ele.details.cov_1 || ele.details['pm2.5_1'] || ele.details['pH1'] || ele.details['hum_1'] || ele.details['conductivity1'])){
              ele.building = 'Commercial_1'
            }else{
              ele.building = 'Commercial_2'
            }
        
          })
          this.notifications=data.data;
        //   this.pagination.length = data.totalElements
         
        //   this.loadingapi=false 
          this._changeDetectorRef.markForCheck();
          }, error =>{
            console.log("error", error)
          })
      }
}
